//
// A service that maintains a list of uploaded files
//
import angular, { IQService } from 'angular';
angular
  .module('fgFiles')
  .factory('fgFileListService', ['$q', 'lodashService', fgFileListService]);

function fgFileListService($q: IQService, lodashService: Lodash) {
  // A list of all the file controls
  // These are identified by a combiation of the fileId AND the stepId
  // This is because the fileId is unique only within a flow Model NOT across flow instances.
  let _fileControls = [];
  let _form = undefined;

  const service = {
    files: [],
    addFile: addFile,
    doesFileControlExist: doesFileControlExist,
    getFilesForStep: getFilesForStep,
    getFilesForControl: getFilesForControl,
    getForm: getForm,
    clearFiles: clearFiles,
    removeFile: removeFile,
    isFileListDirty: isFileListDirty,
    isFileRequired: isFileRequired,
    registerFileControl: registerFileControl,
    getAnyFileListInvalid: getAnyFileListInvalid,
    getFileListFileCount: getFileListFileCount,
    getIsThisFileListInvalid: getIsThisFileListInvalid,
    removeFileControlsForFlow: removeFileControlsForFlow,
    removeAllFileControls: removeAllFileControls,
    removeFileControlsForFlowStep: removeFileControlsForFlowStep,
    setFileListInvalid: setFileListInvalid,
    storeForm: storeForm
  };

  return service;

  //////////// Public API Methods
  function addFile(file, controlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('addFile', arguments, 3);
    const fileList = getFileControl(controlId, stepId);
    fileList.isDirty = true;
    //dont add file if it already exists
    const index = fileList.files.findIndex(function (f) {
      return f.id === file.id;
    });
    if (index < 0) {
      fileList.files.push(file);
    }
  }

  // CLear all files associted with the provided stepId
  function clearFiles(stepId) {
    const stepID = getStepId(stepId);
    lodashService.forEach(_fileControls, function (control) {
      lodashService.remove(control.files, function (f) {
        return f.stepId === stepID; //is this really necessary?
      });
    });
  }
  function doesFileControlExist(controlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('doesFileControlExist', arguments, 2);
    const found = lodashService.find(_fileControls, function (l) {
      return l.id === controlId && l.stepId === stepId;
    });
    return found !== undefined;
  }

  function getFilesForStep(stepId) {
    //get all files uploaded as part of this step
    const stepID = getStepId(stepId);
    const controlsForStep = lodashService.filter(_fileControls, function (f) {
      return f.stepId == stepID;
    });
    let files = [];
    lodashService.forEach(controlsForStep, function (control) {
      files = files.concat(control.files);
    });
    return files;
  }

  function getFilesForControl(controlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('getFilesForControl', arguments, 2);
    const fileList = getFileControl(controlId, stepId);

    //Return if no files could be found for the control
    if (!fileList) return [];

    return fileList.files;
  }

  function getForm() {
    return _form;
  }

  function isFileListDirty(controlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('isFileListDirty', arguments, 2);
    const fileList = getFileControl(controlId, stepId);
    return fileList.isDirty;
  }

  function isFileRequired(fileControlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('isFileRequired', arguments, 2);
    const fileList = getFileControl(fileControlId, stepId);
    return fileList && fileList.isRequired;
  }

  function getFileListFileCount(fileControlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('getFileListFileCount', arguments, 2);
    const fileList = getFileControl(fileControlId, stepId);
    return fileList != null ? fileList.files.length : 0;
  }

  function registerFileControl(controlId, stepId, flowInstanceId, required) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('registerFileControl', arguments, 4);
    const found = lodashService.find(_fileControls, function (fc) {
      return (
        fc.id === controlId &&
        fc.flowInstanceId === flowInstanceId &&
        fc.stepId === stepId
      );
    });

    if (!found) {
      _fileControls.push({
        id: controlId,
        stepId: getStepId(stepId),
        flowInstanceId: flowInstanceId,
        files: [],
        isDirty: false,
        isRequired: required
      });
    }
  }

  function getIsThisFileListInvalid(fileControlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('getIsThisFileListInvalid', arguments, 2);
    //TODO is this used?
    const fileList = getFileControl(fileControlId, stepId);
    return fileList && fileList.isRequired && fileList.isDirty; //submitted how??
  }

  function getAnyFileListInvalid(flowInstanceId) {
    //why do required fields on their own not work
    const forFlow = lodashService.filter(_fileControls, function (c) {
      return c.flowInstanceId == flowInstanceId;
    });

    const invalid = lodashService.some(forFlow, function (f) {
      return f.invalid == true;
    });

    return invalid;
  }

  function setFileListInvalid(fileControlId, stepId, flowInstanceId, invalid) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('setFileListInvalid', arguments, 4);
    const fileControl = getFileControl(fileControlId, stepId);

    //  [FLOW-5473] Exit early if the fileControl is null or empty.
    if (fileControl == undefined || fileControl == '') {
      return;
    }

    fileControl.invalid = invalid;
    fileControl.flowInstanceId = flowInstanceId;
  }

  function removeFileControlsForFlow(flowInstanceId) {
    lodashService.remove(_fileControls, function (control) {
      return control.flowInstanceId == flowInstanceId;
    });
  }

  function removeAllFileControls() {
    _fileControls = [];
  }

  function removeFileControlsForFlowStep(flowInstanceId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('removeFileControlsForFlowStep', arguments, 2);
    lodashService.remove(_fileControls, function (control) {
      return (
        control.flowInstanceId === flowInstanceId && control.stepId === stepId
      );
    });
  }

  function removeFile(file, controlId, stepId) {
    // eslint-disable-next-line prefer-rest-params
    doArgumentsCheck('removeFile', arguments, 3);

    const fileList = getFileControl(controlId, stepId);
    const index = lodashService.findIndex(fileList.files, function (f) {
      return f.id === file.id;
    });
    if (index !== -1) {
      fileList.files.splice(index, 1);
      fileList.isDirty = true;
    }

    return $q.when();
  }

  function storeForm(form) {
    _form = form;
  }

  //////////// Private Methods
  function getStepId(stepId) {
    if (stepId == undefined || stepId == '') {
      //stepId will be undefined if we are uploading a file for a flow not yet started
      //to save having to pass in empty guid id everywhere, handle this here
      stepId = '00000000-0000-0000-0000-000000000000';
    }
    return stepId;
  }

  //temporary method to help track down any scenarions where arguments are not corret
  function doArgumentsCheck(name, args, length) {
    if (args.length !== length) {
      throw new Error(
        'you supplied ' +
          args.length +
          ' args, but the' +
          name +
          'method requires ' +
          args
      );
    }
  }

  function getFileControl(controlId, stepId) {
    return lodashService.find(_fileControls, function (l) {
      return l.id === controlId && l.stepId === stepId;
    });
  }
}

export type FgFileListServiceType = ReturnType<typeof fgFileListService>;
