angular
  .module('fg')
  .directive('fgFieldFileUpload', [
    'fgFieldFileUploadLinkFn',
    function (fgFieldFileUploadLinkFn) {
      //See: https://bizflo.atlassian.net/wiki/spaces/TECH/pages/50987034/Notes+On+File+Upload

      return {
        replace: true,
        controllerAs: 'ctrl',
        controller: [
          '$http',
          '$q',
          'sessionService',
          'fgFileListService',
          '$scope',
          'browserUtilsService',
          function (
            $http,
            $q,
            sessionService,
            fgFileListService,
            $scope,
            browserUtilsService
          ) {
            var ctrl = this;
            ctrl.showErrorStyles = showErrorStyles;
            ctrl.fgFileListService = fgFileListService;
            ctrl.addFile = addFile;
            ctrl.getFile = getFile;
            $scope.isMobileApp = browserUtilsService.isCordovaApp();

            function addFile(file) {
              fgFileListService.addFile(file, ctrl.fieldId, ctrl.stepId);
            }

            function getFile(filepath) {
              if (!filepath) {
                return $q.when();
              }

              //Request file data from server, attaching auth header and token (from Auth0)
              var requestConfig = {
                method: 'Get',
                headers: {
                  Authorization: 'Bearer ' + sessionService.getToken()
                },
                url: filepath,
                cache: 'true'
              };

              return $http(requestConfig);
            }

            function showErrorStyles() {
              var fileCount = fgFileListService.getFileListFileCount(
                ctrl.fieldId,
                ctrl.stepId
              );

              return (
                fileCount == 0 &&
                fgFileListService.isFileRequired(ctrl.fieldId, ctrl.stepId) &&
                (fgFileListService.isFileListDirty(ctrl.fieldId, ctrl.stepId) ||
                  (ctrl.form.state && ctrl.form.state.$submitted))
              );
            }
          }
        ],
        templateUrl:
          'angular-form-gen/field-templates/default/fileupload.ng.html',
        link: fgFieldFileUploadLinkFn
      };
    }
  ])
  .factory('fgFieldFileUploadLinkFn', [
    'pubsubService',
    function (pubsubService) {
      return function ($scope, $element, $attrs, ctrls) {
        ctrls.fieldId = $attrs.fileControlId;
        ctrls.stepId = $attrs.fileStepId;
        ctrls.form = $scope.$parent.form;

        initialiseFile();

        $scope.$watch(function () {
          return ctrls.form.data && ctrls.form.data[ctrls.fieldId];
        }, initialiseFile);

        //if there is a file already uploaded against this step, add it to the list
        function initialiseFile() {
          ctrls.fgFileListService.clearFiles(ctrls.stepId);
          if (ctrls.form.data && ctrls.form.data[ctrls.fieldId]) {
            var files = ctrls.form.data[ctrls.fieldId];

            for (var f = 0; f < files.length; f++) {
              var filepath = files[f].filepath;

              getFile(filepath, files[f].downloadLink);
            }
          }
        }

        function getFile(filepath, downloadLink) {
          //get the file from the server (so we an get filename and size)
          ctrls.getFile(filepath).then(function (response) {
            if (!response) {
              return;
            }

            var fileData = response.data;
            var file = {
              filename: fileData.filename,
              //take the last guid in the string this is the ID
              id: downloadLink.match(/(\S{8}-\S{4}-\S{4}-\S{4}-\S{12}$)/)[0],
              key: ctrls.fieldId,
              downloadLink: downloadLink,
              size: fileData.size
            };
            //for it to show up in the control it needs to be added to the file list
            ctrls.addFile(file);
            pubsubService.publish('FILEUPLOAD_UPLOAD_COMPLETED');
          });
        }
      };
    }
  ]);
