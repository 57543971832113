angular.module('fg').directive('fgDropzone', function () {
  return {
    templateUrl: 'angular-form-gen/edit/canvas/dropzone/dropzone.ng.html',
    link: function ($scope, $element, $attrs, ctrls) {
      const el = $element[0];
      el.addEventListener('dragenter', dragEnter);
      el.addEventListener('dragleave', dragFinished);
      el.addEventListener('drop', dragFinished);
      el.addEventListener('dragend', dragFinished);

      function dragEnter(event) {
        this.classList.add('over');
      }

      function dragFinished(event) {
        this.classList.remove('over');
      }
    }
  };
});
