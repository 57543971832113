angular.module('fg').controller('tableDefaultController', [
  '$scope',
  'pubsubService',
  function ($scope, pubsubService) {
    /// The purpose of this controller is to set the parent form valid / invalid in response to the
    /// changed in the validity of the table.
    /// The table will be INVALID if one or more required cells have no value    <><><><><.
    var ctrl = this;
    ctrl.init = init;
    ctrl.onFormValid = onFormValid;
    ctrl.onFormInvalid = onFormInvalid;

    function init(form, field) {
      ctrl.form = form;
      $scope.form = form;
      ctrl.field = field;
    }
    function onFormValid() {
      if (ctrl.form && ctrl.form.state) {
        ctrl.form.state.$invalid = false;
        ctrl.form.state.$valid = true;
      }
      //for some reason setting the flags is not always enough, therefore
      //raise an event that we can subscribe to in the flow-form-component to enable submit
      pubsubService.publish('FORM_TABLE_VALID');
    }
    function onFormInvalid() {
      if (ctrl.form && ctrl.form.state) {
        ctrl.form.state.$invalid = true;
        ctrl.form.state.$valid = false;
      }
      //for some reason setting the flags is not always enough, therefore
      //raise an event that we can subscribe to in the flow-form-component to prevent submit
      pubsubService.publish('FORM_TABLE_INVALID');
    }
  }
]);
