angular.module('fg').controller('fgEditPaletteController', [
  '$scope',
  'pubsubService',
  function ($scope, pubsubService) {
    var $ctrl = this;
    $scope.templateFilter = function (template) {
      return (
        (!$scope.selectedCategory || $scope.selectedCategory[template.type]) &&
        !template.hideInPalette
      );
    };
    $scope.addField = function (template) {
      pubsubService.publish(
        'WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED',
        'fgEditPaletteController'
      );
      pubsubService.publish('WORKFLOW_DESIGNER_FORM_FIELDS_ADDED', template);
    };
  }
]);
