angular.module('fg').controller('fgEditCanvasController', [
  '$scope',
  'dqUtils',
  '$timeout',
  'pubsubService',
  'sessionService',
  function ($scope, dqUtils, $timeout, pubsubService, sessionService) {
    let ctrl = this;
    const subscriberId = 'fgEditCanvasController';

    // - - - 8-< - - - - - - - - - - - - - - - - - - - - -
    // Drag & drop
    // - - - 8-< - - - - - - - - - - - - - - - - - - - - -

    $scope.$on('dqDragBegin', function () {
      var dragData = dqUtils.dragData();
      $scope.draggingFieldIndex = dragData.data.index;
      $scope.dragging = true;
      ctrl.minimizeFields();
    });

    $scope.$on('dqDragEnd', function () {
      $scope.dragging = false;
      ctrl.maximizeFields();
    });

    $scope.$on('$destroy', () => {
      pubsubService.unsubscribeAll(subscriberId);
    });
    this.dragBeginCanvasField = function (index, field) {
      // Delay is set to prevent browser from copying adjusted html as copy image

      $timeout(function () {
        field.$_isDragging = true;
      }, 1);

      $timeout(function () {
        const el = document.getElementById('drag-field-origin-' + index);
        el.scrollIntoView(true);
      }, 10);

      return { source: 'canvas', field: field, index: index };
    };

    this.dragEndCanvasField = function (field) {
      // IE Fix: ensure this is fired after the drag begin

      $timeout(function () {
        field.$_isDragging = false;
      }, 10);
    };

    //passing index as input to tell that field should be moved to this particular index
    this.drop = function (movedToIndex) {
      var dragData = dqUtils.dragData();
      if (dragData && dragData.data) {
        var field = dragData.data.field;
        var source = dragData.data.source;
        var index = dragData.data.index;

        if (source === 'palette') {
          this.shrunkFields();
          $scope.schemaCtrl.addField(field, movedToIndex);
        } else if (source === 'canvas') {
          $scope.schemaCtrl.moveField(index, movedToIndex);
        }

        // IE fix: not calling dragEnd sometimes
        field.$_isDragging = false;
        pubsubService.publish('CARD_DESIGNER_DRAG_DROP');
        $timeout(function () {
          // dropzone-directive is meant to do this but doesn't always get a drag-end event
          document
            .querySelector('.fg-edit-canvas')
            .querySelectorAll('fg-dropzone.over')
            .forEach((element) => element.classList.remove('over'));
          const targetIndex =
            movedToIndex > index ? --movedToIndex : movedToIndex;
          const el = document.getElementById('canvas-field-' + targetIndex);
          el.scrollIntoView(true);
        }, 200);
      } else {
        throw Error('Drop without data');
      }
    };

    this.collapseFields = function () {
      ($scope.schema.fields || []).forEach((field) => {
        field.displayProperties = false;
        if (field.type === 'image' && (!field.value || field.disabled)) {
          field.minimized = true;
        }
      });
    };

    this.minimizeFields = function () {
      ($scope.schema.fields || []).forEach((field) => {
        field.minimized = true;
      });
    };

    this.maximizeFields = function () {
      ($scope.schema.fields || []).forEach((field) => {
        if (
          field.type !== 'image' ||
          (field.value && !field.disabled) ||
          field.displayProperties
        ) {
          delete field.minimized;
        }
      });
    };

    this.shrunkFields = function () {
      ($scope.schema.fields || []).forEach((field) => {
        field.shrunk = true;
      });
    };

    pubsubService.subscribe(
      'WORKFLOW_DESIGNER_FORM_FIELDS_ADDED',
      function (event, template) {
        ctrl.shrunkFields();
        $scope.schemaCtrl.addField(template, undefined, true);
      },
      subscriberId
    );
  }
]);
