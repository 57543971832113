angular
  .module('fg')
  .directive('fgAttachDocumentFileList', [fgAttachDocumentFileList]);

function fgAttachDocumentFileList() {
  var directive = {
    restrict: 'E',
    require: ['?^fgForm', 'fgAttachDocumentFileList'],
    scope: {
      //id of the file upload control associated with this list
      fieldId: '<',
      nodeId: '<',
      form: '='
    },
    bindToController: true,
    controller: [
      '$rootScope',
      '$timeout',
      '$scope',
      '$q',
      '$http',
      '$document',
      'flowStore',
      'pubsubService',
      'lodashService',
      'notificationService',
      'ngDialog',
      'fgAttachDocumentFileListService',
      function controller(
        $rootScope,
        $timeout,
        $scope,
        $q,
        $http,
        $document,
        flowStore,
        pubsubService,
        lodashService,
        notificationService,
        ngDialog,
        fgAttachDocumentFileListService
      ) {
        var ctrl = this;
        ctrl.fgAttachDocumentFileListService = fgAttachDocumentFileListService;
        ctrl.touched = false;
        ctrl.init = init;
        ctrl.getFiles = getFiles;
        ctrl.removeFile = removeFile;
        ctrl.removeAll = removeAll;
        ctrl.cleanup = cleanup;
        ctrl.showFileRequiredMessage = showFileRequiredMessage;
        ctrl.files = [];

        // PUBLIC  METHODS  /////////////////////////////////////////////////////////////

        // Get files saved against this node (uses the nodeId passed to the formCtrl to filter)
        function getFiles() {
          ctrl.files = fgAttachDocumentFileListService.getFilesForControl(
            ctrl.fieldId,
            ctrl.nodeId
          );
          return ctrl.files;
        }

        // clean up all resources when component destroyed
        function cleanup(fgFormCtrl) {
          if (fgFormCtrl) {
            pubsubService.unsubscribeAll('file-list' + ctrl.fieldId);
          }
        }

        function init(fgFormCtrl) {
          if (fgFormCtrl != null) {
            fgFormCtrl.nodeId = ctrl.nodeId != null ? ctrl.nodeId : null;

            ctrl.fgFormCtrl = fgFormCtrl;
            //ctrl.flowInstanceId = fgFormCtrl.flowInstanceId;
            fgAttachDocumentFileListService.storeForm(fgFormCtrl);
            fgAttachDocumentFileListService.registerFileControl(
              this.fieldId,
              fgFormCtrl.stepId,
              ctrl.nodeId
            );
            //validateFileRequired();
            pubsubService.subscribe(
              'FILEUPLOAD_UPLOAD_COMPLETED',
              validateFileRequired,
              'file-list' + ctrl.fieldId
            );
          }
        }

        function removeAll() {
          confirmDelete().then(function () {
            lodashService.each(ctrl.files, function (file) {
              removeFile(file, true);
            });
          });
        }

        function removeFile(file, supressConfirmation) {
          confirmDelete(supressConfirmation).then(
            function () {
              pubsubService.publish('FILEUPLOAD_REMOVAL_STARTED');
              //remove file from client first (optimisitic) this prevents the UI trying to upload in progress form files
              //if the user moves somewhere else whilst delete in progress.
              fgAttachDocumentFileListService.removeFile(
                file,
                ctrl.fieldId,
                ctrl.nodeId
              );

              /*
                                the actual mapping of the file removed will be checked and updated on publish.
                            */

              //var user = $rootScope.sessionService.getUser();
              //$http.post($rootScope.APP_CONFIG.apiBaseUrl + 'files/remove/' + user.businessId + '/' + user.id + '/' + file.id + '/' + ctrl.nodeId, {})
              //    .then(function () {
              //        pubsubService.publish('FILEUPLOAD_REMOVAL_COMPLETED', fgAttachDocumentFileListService.getFilesForControl(file.key, ctrl.nodeId).length);
              notificationService.showSuccessToast(file.filename + ' Removed');
              //        validateFileRequired();
              //    });
            },
            function () {
              //user cancelled do nothing
            }
          );
        }

        // PRIVATE METHODS  /////////////////////////////////////////////////////////////

        function confirmDelete(supressConfirmation) {
          if (flowStore.get('dontShowAgain') != undefined) {
            supressConfirmation = true;
          }

          //supress the confirmation if the user clicked removeAll and they have already confirmed delete
          if (!supressConfirmation) {
            //todo to reusable service
            return ngDialog
              .openConfirm({
                className: 'attach-document-confim-delete',
                showClose: false,
                template:
                  'angular-form-gen/form/files/file.confirmDelete.ng.html',
                scope: $scope
              })
              .then(
                function (dontShowAgain) {
                  setDontShowAgainFlag(dontShowAgain);
                  return $q.when();
                },
                function () {
                  return $q.reject();
                }
              );
          }
          return $q.when();
        }

        function showFileRequiredMessage() {
          return (
            fgAttachDocumentFileListService.isFileRequired(
              ctrl.fieldId,
              ctrl.nodeId
            ) &&
            (fgAttachDocumentFileListService.isFileListDirty(
              ctrl.fieldId,
              ctrl.nodeId
            ) ||
              (ctrl.form.state && ctrl.form.state.$submitted)) &&
            fgAttachDocumentFileListService.getFilesForControl(
              ctrl.fieldId,
              ctrl.nodeId
            ).length == 0
          );
        }

        function validateFileRequired() {
          //check to to see if the form is invalid as there are not files uploaded when they are required.
          //the form may  have  mix of optional and required fields, so we only want to look at the required ones.
          if (
            fgAttachDocumentFileListService.isFileRequired(
              ctrl.fieldId,
              ctrl.nodeId
            )
          ) {
            var invalid =
              fgAttachDocumentFileListService.getFilesForControl(
                ctrl.fieldId,
                ctrl.nodeId
              ).length == 0;

            if (invalid) {
              pubsubService.publish('FILEUPLOAD_FILE_ERROR', {
                fileControlId: ctrl.fieldId,
                flowInstanceId: ctrl.flowInstanceId
              });
            } else {
              pubsubService.publish('FILEUPLOAD_FILE_VALID', {
                fileControlId: ctrl.fieldId,
                flowInstanceId: ctrl.flowInstanceId
              });
            }
          }
        }

        function setDontShowAgainFlag(dontShowAgain) {
          if (dontShowAgain) {
            flowStore.set('dontShowAgain', true);
          }
        }
      }
    ],
    controllerAs: 'ctrl',
    link: function (scope, element, attrs, ctrls) {
      var fgFormCtrl = ctrls[0];
      var fgFileListCtrl = ctrls[1];
      fgFileListCtrl.init(fgFormCtrl);
      scope.$on('$destroy', () => {
        fgFileListCtrl.cleanup(fgFormCtrl);
      });
    },

    template: [
      '<div ng-show="ctrl.files.length > 1" class="pt-5"><a class="ml-15 cursor-pointer" ng-click="ctrl.removeAll()">Remove All</a></div>',
      '<div><ul class="file-list">',
      '<li ng-repeat="file in ctrl.getFiles() | orderBy : \'filename\'" class="clearfix">',
      '<a flow-download-link="{{::file.downloadLink}}" target="_blank"> {{::file.filename}}</a><span>({{::file.size}}K)&nbsp;({{::file.uploadedDate}})</span>',
      '<i ng-click="ctrl.removeFile(file)" class="fa-light fa-xmark fa-lg"></i></li>',
      '</ul>',
      '<div class="pl-10 pt-5 secondary-text" ng-show="ctrl.files.length === 0"><em>Drag and Drop your files here or click the Upload button</em></div>',
      '</div>'
    ].join('')
  };
  return directive;
}
