angular.module('fg').controller('fgEditController', [
  '$scope',
  'fgUtils',
  '$location',
  'fgConfig',
  'pubsubService',
  '$timeout',
  function ($scope, fgUtils, $location, fgConfig, pubsubService, $timeout) {
    $scope.templates = angular.copy(fgConfig.fields.templates);

    $scope.$watch(function (newValue, oldValue) {
      var schema = $scope.schemaCtrl.model();

      // Seems that this watch is sometimes fired after the scope has been destroyed(?)

      if (schema) {
        var fields = schema.fields;

        if (fields) {
          var i = fields.length;

          while (--i >= 0 && !schema.$$_invalid) {
            schema.$$_invalid = fields[i].$$_invalid;

            fields[i].disabled =
              (fields[i].type === 'image' && fields[i].disabled) ||
              fields[i].type === 'fileupload';
          }
        }
      }
    });
  }
]);
